import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import debounce from 'lodash.debounce';
import axios from 'axios';
import CoinList from './CoinList';
import TradeQuoteBuyModal from '../Modals/TradeQuoteBuyModal';

export default function BuyForm() {
    const [showCoinList, setShowCoinList] = useState(false);
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [tradeData, setTradeData] = useState({
        coin1: '',
        coin2: ''
    });
    const { coin1, coin2 } = tradeData;

    const { tradePairs, selectedPair } = useSelector(state => state.coin);
    const defaultPair = selectedPair ||
        (tradePairs && tradePairs.length > 0 && tradePairs.find(pair => pair.default)) || (tradePairs && tradePairs[0]);

    const [adjustedCoin1Price, setAdjustedCoin1Price] = useState(0);
    const [adjustedCoin2Price, setAdjustedCoin2Price] = useState(0);

    const fetchAdjustedPrice = async (symbol) => {
        try {
            const response = await axios.get(`https://min-api.cryptocompare.com/data/price?fsym=${symbol}&tsyms=USDT`);
            return response.data['USDT'];
        } catch (error) {
            console.error('Error fetching prices:', error);
            return 0;
        }
    };

    const updatePrices = async () => {
        const coin1Price = await fetchAdjustedPrice(defaultPair?.coin1?.shortCode);
        const coin2Price = await fetchAdjustedPrice(defaultPair?.coin2?.shortCode);
        setAdjustedCoin1Price(coin1Price);
        setAdjustedCoin2Price(coin2Price);
    };

    useEffect(() => {
        updatePrices();
    }, [defaultPair]);

    const debouncedCalculateCoin2Value = useCallback(debounce((coin1Amount) => {
        if (coin1Amount && adjustedCoin1Price && adjustedCoin2Price) {
            const coin2AmountOriginal = (coin1Amount * adjustedCoin1Price) / adjustedCoin2Price;
            const coin2Amount = coin2AmountOriginal + (coin2AmountOriginal * 0.01);
            setTradeData(prevState => ({
                ...prevState,
                coin2: coin2Amount.toFixed(2)
            }));
        }
    }, 1000), [adjustedCoin1Price, adjustedCoin2Price]);

    useEffect(() => {
        if (coin1) {
            debouncedCalculateCoin2Value(parseFloat(coin1));
        } else {
            setTradeData(prevState => ({
                ...prevState,
                coin2: ''
            }));
        }
        return () => {
            debouncedCalculateCoin2Value.cancel();
        };
    }, [coin1, debouncedCalculateCoin2Value]);

    const handleInputChange = (e) => {
        const { id, value } = e.target;
        setTradeData({
            ...tradeData,
            [id]: value
        });
    };

    const toggleCoinShow = () => setShowCoinList(!showCoinList);
    const toggleModal = () => setShowModal(!showModal); // Toggle modal

    return (
        <form>
            <div className="text-center mx-2">
                <h5 className="font-bold">{`Buy ${defaultPair?.coin1.shortCode} with ${defaultPair?.coin2.shortCode}`}</h5>
            </div>
            <div className="d-flex align-items-center justify-content-between p-2 rounded border shadow-sm mb-4 main-coin" onClick={toggleCoinShow}>
                <div className="d-flex align-items-center justify-content-between w-100">
                    <img alt={defaultPair?.coin1.name} className="img-fluid coin-symbol" src={defaultPair?.coin1.icon} />
                    <p className="text-center mb-0" style={{ flex: 1 }}>{`${defaultPair?.coin1.shortCode}/${defaultPair?.coin2.shortCode}`}</p>
                    <img alt={defaultPair?.coin2.name} className="img-fluid coin-symbol" src={defaultPair?.coin2.icon} />
                </div>
                <div className="ps-3">
                    <div className="down-arrow"></div>
                </div>
            </div>
            <CoinList show={showCoinList} closeCoinList={() => setShowCoinList(false)} />
            <div className="d-flex align-items-center mb-4">
                <div className="flex-grow-1">
                    <label htmlFor="coin1">Receive</label>
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            id='coin1'
                            name='coin1'
                            placeholder="0"
                            value={coin1}
                            onChange={handleInputChange}
                            step="any"
                        />
                        <span className="input-group-text"> <img alt={defaultPair?.coin1.name} className="img-fluid coin-symbol me-1" src={defaultPair?.coin1.icon} />{defaultPair?.coin1.shortCode}</span>
                    </div>
                </div>
            </div>
            <div className="d-flex align-items-center mb-4">
                <div className="flex-grow-1">
                    <label htmlFor="coin2">Pay</label>
                    <div className="input-group">
                        <input
                            type="number"
                            className="form-control"
                            id='coin2'
                            name='coin2'
                            placeholder="0"
                            value={coin2}
                            step="any"
                        />
                        <span className="input-group-text"> <img alt={defaultPair?.coin2.name} className="img-fluid coin-symbol me-1" src={defaultPair?.coin2.icon} />{defaultPair?.coin2.shortCode}</span>
                    </div>
                </div>
            </div>
            <div>
                <button type="button" onClick={toggleModal} className="btn btn-info w-100 mb-2" disabled={coin1 <= 0}>Get Quote</button>
            </div>

            <TradeQuoteBuyModal
                show={showModal}
                onHide={toggleModal}
                coin1={defaultPair?.coin1}
                coin2={defaultPair?.coin2}
                coin1Amount={coin1}
                coin2Amount={coin2}
                exchangeRate={(adjustedCoin1Price / adjustedCoin2Price).toFixed(4)}
            />
        </form>
    );
}
