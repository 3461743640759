import React from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

export default function BlogDetails() {
    const mode = useSelector((state) => state.user?.mode);
    const { slug } = useParams();

    // Mock data (replace with API or state data)
    const blogData = [
        {
            imgSrc: "/assets/images/blog/1.png",
            tag: "Forex trading",
            title: "Swing Trading Definition",
            description: "Our platform is not about trading—it's also a hub for knowledge and learning. We provide resources.",
            authorImg: "/assets/images/blog/author/1.png",
            authorName: "Vasha Gueye",
            date: "20/6/2023"
        },
        {
            imgSrc: "/assets/images/blog/2.png",
            tag: "Trading market",
            title: "Hedge funds work?",
            description: "To cater to your individual trading preferences, we offer a variety of order types, including market.",
            authorImg: "/assets/images/blog/author/2.png",
            authorName: "Abhivibha Kanmani",
            date: "30/5/2023"
        },
        {
            imgSrc: "/assets/images/blog/3.png",
            tag: "Investment",
            title: "Options Trading business?",
            description: "Security is our top priority, and we employ robust measures to ensure the safety of your funds.",
            authorImg: "/assets/images/blog/author/3.png",
            authorName: "Hulya Aydin",
            date: "12/07/2023"
        },
        {
            imgSrc: "/assets/images/blog/1.png",
            tag: "Forex trading",
            title: "Swing Trading Definition",
            description: "Our platform is not about trading—it's also a hub for knowledge and learning. We provide resources.",
            authorImg: "/assets/images/blog/author/1.png",
            authorName: "Vasha Gueye",
            date: "20/6/2023"
        },
        {
            imgSrc: "/assets/images/blog/2.png",
            tag: "Trading market",
            title: "Hedge funds work?",
            description: "To cater to your individual trading preferences, we offer a variety of order types, including market.",
            authorImg: "/assets/images/blog/author/2.png",
            authorName: "Abhivibha Kanmani",
            date: "30/5/2023"
        },
    ];

    // Find blog by slug
    const blog = blogData.find((b) => slug === b.title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, ''));

    if (!blog) {
        return <div>Blog not found!</div>;
    }

    return (
        <div className='page blog-details'>
            <div className="container mt-5">
                <div className="row">
                    <div className="col">
                        <h2 className={`mb-3 ${mode === 'dark' ? 'text-light' : ''}`}>{blog.title}</h2>
                        <img src={blog.imgSrc} alt={blog.title} className="mb-4 img-fluid" />
                        <p className={`${mode === 'dark' ? 'text-light' : ''}`}><strong>By:</strong> {blog.authorName} | <strong>Date:</strong> {blog.date}</p>
                        <p className={`${mode === 'dark' ? 'text-light' : ''}`}>{blog.description}</p>
                    </div>
                </div>
            </div>
        </div>
    );
}
