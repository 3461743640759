import React from 'react';
import { useSelector } from 'react-redux';

export default function AboutUs() {
    const mode = useSelector((state) => state.user?.mode);

    return (
        <div className="page">
            <div className="container mt-5">
                <div className="row">
                    <div className="col">
                        <h2 className={`mb-3 ${mode === 'dark' ? 'text-light' : ''}`}>About Us</h2>
                        <p className={`mb-4 ${mode === 'dark' ? 'text-light' : ''}`}>
                            Welcome to <strong>Indexa</strong>, your trusted partner in the dynamic world of cryptocurrency. We are a cutting-edge crypto exchange platform that empowers users to trade, buy, and sell cryptocurrencies seamlessly and securely.
                        </p>
                        <p className={`mb-4 ${mode === 'dark' ? 'text-light' : ''}`}>
                            At Indexa, we believe in making cryptocurrency trading accessible to everyone, whether you're a seasoned trader or just beginning your journey in the crypto space. With our intuitive platform, users can:
                        </p>
                        <ul className={`mb-4 list-style-disc ${mode === 'dark' ? 'text-light' : ''}`}>
                            <li>Trade a wide range of cryptocurrencies with competitive fees.</li>
                            <li>Buy crypto using fiat currencies quickly and conveniently.</li>
                            <li>Sell crypto with ease and receive payments in your preferred currency.</li>
                            <li>Engage in over-the-counter (OTC) trades for large-scale transactions.</li>
                            <li>Explore advanced trading tools and market insights to make informed decisions.</li>
                        </ul>
                        <p className={`mb-4 ${mode === 'dark' ? 'text-light' : ''}`}>
                            Our mission is to foster financial independence and innovation by offering a reliable and secure platform for all your cryptocurrency needs. We are committed to transparency, user satisfaction, and providing state-of-the-art security features to ensure your assets are always safe.
                        </p>
                        <p className={`mb-4 ${mode === 'dark' ? 'text-light' : ''}`}>
                            Join the thousands of users who trust Indexa for their crypto trading needs. Together, let's unlock the full potential of the cryptocurrency revolution and navigate the future of finance.
                        </p>
                        <p className={`mb-4 ${mode === 'dark' ? 'text-light' : ''}`}>
                            <strong>Start your crypto journey with Indexa today!</strong>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
