import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

export default function Blogs() {
    const mode = useSelector((state) => state.user?.mode);
    const blogData = [
        {
            imgSrc: "/assets/images/blog/1.png",
            tag: "Forex trading",
            title: "Swing Trading Definition",
            description: "Our platform is not about trading—it's also a hub for knowledge and learning. We provide resources.",
            authorImg: "/assets/images/blog/author/1.png",
            authorName: "Vasha Gueye",
            date: "20/6/2023"
        },
        {
            imgSrc: "/assets/images/blog/2.png",
            tag: "Trading market",
            title: "Hedge funds work?",
            description: "To cater to your individual trading preferences, we offer a variety of order types, including market.",
            authorImg: "/assets/images/blog/author/2.png",
            authorName: "Abhivibha Kanmani",
            date: "30/5/2023"
        },
        {
            imgSrc: "/assets/images/blog/3.png",
            tag: "Investment",
            title: "Options Trading business?",
            description: "Security is our top priority, and we employ robust measures to ensure the safety of your funds.",
            authorImg: "/assets/images/blog/author/3.png",
            authorName: "Hulya Aydin",
            date: "12/07/2023"
        },
        {
            imgSrc: "/assets/images/blog/1.png",
            tag: "Forex trading",
            title: "Swing Trading Definition",
            description: "Our platform is not about trading—it's also a hub for knowledge and learning. We provide resources.",
            authorImg: "/assets/images/blog/author/1.png",
            authorName: "Vasha Gueye",
            date: "20/6/2023"
        },
        {
            imgSrc: "/assets/images/blog/2.png",
            tag: "Trading market",
            title: "Hedge funds work?",
            description: "To cater to your individual trading preferences, we offer a variety of order types, including market.",
            authorImg: "/assets/images/blog/author/2.png",
            authorName: "Abhivibha Kanmani",
            date: "30/5/2023"
        },
    ];

    // Helper function to generate slug
    const generateSlug = (title) => title.toLowerCase().replace(/ /g, '-').replace(/[^\w-]+/g, '');

    return (
        <div className='page'>
            <div className="container mt-5">
                <div className="row">
                    <h2 className={`mb-3 ${mode === 'dark' ? 'text-light' : ''}`}>Blogs</h2>
                    {blogData.map((blog) => (
                        <div className="col-12 col-md-6 col-lg-4" key={blog.id}>
                            <Link to={`/blogs/${generateSlug(blog.title)}`}>
                                <div className="blog__item mb-4">
                                    <div className="blog__thumb mb-2">
                                        <img src={blog.imgSrc} alt={blog.title} />
                                    </div>
                                    <div className="blog__content">
                                        <h5 className={`mb-1 ${mode === 'dark' ? 'text-light' : ''}`}>{blog.title}</h5>
                                        <p className={`${mode === 'dark' ? 'text-light' : ''}`}>{blog.description}</p>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    ))}
                </div>
            </div>
        </div >
    );
}