import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import DataTable from 'react-data-table-component';

export default function PairPriceTable() {
    const tradePairs = useSelector((state) => state.coin.tradePairs);
    const [pairData, setPairData] = useState([]);
    const [priceChanges, setPriceChanges] = useState({}); // Store price state for each pair

    // Function to fetch prices for each pair
    const fetchPairPrices = async () => {
        try {
            const usdtPairs = tradePairs.filter(pair => pair.coin2.shortCode === 'USDT'); // Filter pairs with coin2 as USDT
            const pairSymbols = usdtPairs.map(pair => `${pair.coin1.shortCode}`).join(',');

            const response = await axios.get('https://min-api.cryptocompare.com/data/pricemulti', {
                params: {
                    fsyms: pairSymbols,
                    tsyms: 'USDT', // Fetching prices in USDT
                },
            });

            const updatedPairData = usdtPairs.map(pair => {
                const pairPrice = response.data[`${pair.coin1.shortCode}`]?.USDT || 0;
                return {
                    ...pair,
                    price: pairPrice,
                };
            });

            // Update price changes for each pair (if any)
            setPriceChanges(prevState => {
                const newChanges = { ...prevState };
                updatedPairData.forEach(pair => {
                    const prevPrice = prevState[`${pair.coin1.shortCode}`]?.price || 0;
                    newChanges[`${pair.coin1.shortCode}`] = {
                        price: pair.price,
                        priceChange: pair.price > prevPrice ? 'increase' : pair.price < prevPrice ? 'decrease' : 'same',
                    };
                });
                return newChanges;
            });

            setPairData(updatedPairData);
        } catch (error) {
            console.error('Error fetching pair prices:', error);
        }
    };

    useEffect(() => {
        if (tradePairs.length) {
            fetchPairPrices();
            const interval = setInterval(fetchPairPrices, 1000); // Refresh every 3 seconds
            return () => clearInterval(interval); // Cleanup on unmount
        }
    }, [tradePairs]);

    const columns = [
        {
            name: 'Coin',
            selector: row => (
                <div className="d-flex">
                    <div className="coin-info">
                        <strong>{`${row.coin1.shortCode}/${row.coin2.shortCode}`}</strong>
                    </div>
                </div>
            ),
        },
        {
            name: 'Price (USDT)',
            selector: row => {
                const change = priceChanges[`${row.coin1.shortCode}`]?.priceChange;
                const bgColor =
                    change === 'increase' ? 'bg-success text-white' :
                        change === 'decrease' ? 'bg-danger text-white' : '';

                return (
                    <div className={`p-2 text-end ${bgColor}`} style={{ transition: 'background-color 0.5s' }}>
                        <strong>{row.price.toFixed(2)} USDT</strong>
                    </div>
                );
            },
            right: true,
            sortable: true,
        },
    ];

    return (
        <DataTable
            columns={columns}
            data={pairData}
            highlightOnHover
            theme="default"
        />
    );
}
