import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { setSelectedPairs } from '../../redux/coinSlice';

export default function CoinList({ show, closeCoinList }) {
    const tradePairs = useSelector(state => state.coin.tradePairs);
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState('');
    const location = useLocation();

    const handleSelectPair = (pair) => {
        dispatch(setSelectedPairs(pair));
        closeCoinList(); // Hide the CoinList after selecting a pair
    };

    const filteredPairs = tradePairs.filter(pair => {
        const pairName = `${pair.coin1.shortCode}/${pair.coin2.shortCode}`.toLowerCase();
        return pairName.includes(searchTerm.toLowerCase());
    });

    // Determine the class based on the current route
    const coinListClass = location.pathname === '/' ? 'd-none coinList-sm' : 'd-none coinList';

    return (
        <div className={show ? coinListClass.replace('d-none', 'd-block') : coinListClass}>
            <div className="row">
                <div className="col-12">
                    <input
                        className="form-control"
                        name="search"
                        type="text"
                        placeholder="Search for Market"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)} // Update search term on input change
                    />
                </div>
            </div>
            <div className="row mt-2">
                <div className="col-12">
                    <div className="coin-types d-flex justify-content-center">
                        {/* Filters or buttons can be implemented here */}
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-12">
                    <hr />
                    {filteredPairs.length > 0 ? (
                        filteredPairs.map((pair, index) => (
                            <div
                                className="d-flex align-items-center justify-content-between p-2 rounded border shadow-sm mb-4 main-coin"
                                key={index}
                                onClick={() => handleSelectPair(pair)}
                            >
                                <div className="d-flex align-items-center justify-content-between w-100">
                                    <img alt={pair.coin1.name} className="img-fluid coin-symbol" src={pair.coin1.icon} />
                                    <p className="text-center mb-0" style={{ flex: 1 }}>{`${pair.coin1.shortCode}/${pair.coin2.shortCode}`}</p>
                                    <img alt={pair.coin2.name} className="img-fluid coin-symbol" src={pair.coin2.icon} />
                                </div>
                            </div>
                        ))
                    ) : (
                        <p className="text-center">No matching pairs found</p>
                    )}
                </div>
            </div>
        </div>
    );
}
