import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa'; // Import the close icon
import { FaPlusCircle, FaArrowRight, FaExchangeAlt, FaPaperPlane, FaDownload } from 'react-icons/fa'; // Import circle icons
import LogTable from '../../components/Tables/LogTable';
import WalletBalances from '../../components/Dashboard/WalletBalances';
import axios from 'axios';
import { setUserDetails } from '../../redux/userSlice';
import PairPriceTable from '../../components/Tables/PairPriceTable';
import { Row, Col } from 'react-bootstrap'; // Import Bootstrap components for layout

export default function Dashboard() {
    const dispatch = useDispatch();
    const bgImage = '/assets/images/welcome_banner.webp';
    const userDetails = useSelector((state) => state.user.userDetails);
    const [isWelcomeVisible, setIsWelcomeVisible] = useState(true); // State for controlling visibility of the banner
    const mode = useSelector((state) => state.user?.mode);

    useEffect(() => {
        const fetchUserDetails = async () => {
            try {
                const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/me`, { withCredentials: true });
                dispatch(setUserDetails(response.data));
            } catch (error) {
                console.error('Error fetching user details:', error);
            }
        };

        fetchUserDetails();
    }, [dispatch]);

    const renderVerificationMessage = () => {
        if (userDetails?.isKyc) {
            return (
                <p>
                    <strong>
                        Your account is verified and unrestricted. Your withdrawal limit is Unlimited.
                    </strong>
                </p>
            );
        } else {
            return (
                <>
                    <p>
                        <strong>
                            Enjoy unrestricted access to the world of cryptocurrency. But first, you need to verify your account. Let's get you set up and ready to trade:
                        </strong>
                    </p>
                    <Link to="/client/kyc" className='btn btn-success px-4 text-white'>Next</Link>
                </>
            );
        }
    };

    return (
        <div className="page">
            <div className="container">
                <div className="row mt-4">
                    <div className="col-12">
                        {isWelcomeVisible && (
                            <div className="bg_welcome" style={{ backgroundImage: `url(${bgImage})` }}>
                                <button
                                    className="close-icon"
                                    onClick={() => setIsWelcomeVisible(false)} // Hide the banner on click
                                >
                                    <FaTimes />
                                </button>
                                <div className="welcome_content">
                                    <h1 className='text-capitalize'>Welcome to Indexa</h1>
                                    {renderVerificationMessage()}
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                {/* Action Buttons Section */}
                <div className="row mt-4">
                    <div className="col-12">
                        <Row className="justify-content-center flex-wrap text-center">
                            <Col xs={2} sm={4} md={2} className="mb-3">
                                <Link to="/buy-and-sell" className="d-flex flex-column align-items-center">
                                    <div className="icon-circle p-2 mb-2">
                                        <FaPlusCircle size={40} />
                                    </div>
                                    <span className={`${mode === 'dark' ? 'text-light' : ''}`}>Buy</span>
                                </Link>
                            </Col>
                            <Col xs={2} sm={4} md={2} className="mb-3">
                                <Link to="/buy-and-sell" className="d-flex flex-column align-items-center">
                                    <div className="icon-circle p-2 mb-2">
                                        <FaArrowRight size={40} />
                                    </div>
                                    <span className={`${mode === 'dark' ? 'text-light' : ''}`}>Sell</span>
                                </Link>
                            </Col>
                            <Col xs={2} sm={4} md={2} className="mb-3">
                                <Link to="/buy-and-sell" className="d-flex flex-column align-items-center">
                                    <div className="icon-circle p-2 mb-2">
                                        <FaExchangeAlt size={40} />
                                    </div>
                                    <span className={`${mode === 'dark' ? 'text-light' : ''}`}>Exchange</span>
                                </Link>
                            </Col>
                            <Col xs={2} sm={4} md={2} className="mb-3">
                                <Link to="/buy-and-sell" className="d-flex flex-column align-items-center">
                                    <div className="icon-circle p-2 mb-2">
                                        <FaPaperPlane size={40} />
                                    </div>
                                    <span className={`${mode === 'dark' ? 'text-light' : ''}`}>Send</span>
                                </Link>
                            </Col>
                            <Col xs={2} sm={4} md={2} className="mb-3">
                                <Link to="/buy-and-sell" className="d-flex flex-column align-items-center">
                                    <div className="icon-circle p-2 mb-2">
                                        <FaDownload size={40} />
                                    </div>
                                    <span className={`${mode === 'dark' ? 'text-light' : ''}`}>Receive</span>
                                </Link>
                            </Col>
                        </Row>
                    </div>
                </div>

                <div className="row p-0 p-md-5">
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <strong>Verification Level</strong>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-2">
                                        {userDetails?.avatar && <img src={userDetails?.avatar} alt={userDetails?.name} className='rounded-circle shadow-4-strong' />}
                                    </div>
                                    <div className="col-10">
                                        <h5><strong>{userDetails?.name}</strong></h5>
                                        <h5><strong>{userDetails?.email}</strong></h5>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col">
                                        <strong>You are currently at Level {userDetails?.KYCProgress}</strong>
                                    </div>
                                </div>
                                <div className="row border-rounded my-3" style={{ backgroundColor: '#f8f8f9' }}>
                                    <b className='mb-0'>Withdrawal Limits:</b>
                                    <p className='mb-0'>Level 1: ZAR 0.00</p>
                                    <p className='mb-0'>Level 2: ZAR 5,000.00</p>
                                    <p className='mb-0'>Level 3: ZAR 50,000,000.00</p>
                                    <p className='mb-0'>Level 4: Unlimited</p>
                                </div>
                            </div>
                        </div>
                        <div className="card my-2">
                            <div className="card-header">
                                <strong>Coins</strong>
                            </div>
                            <div className="card-body">
                                <PairPriceTable />
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="card">
                            <div className="card-header">
                                <strong>Wallets (Your Asset Balances)</strong>
                            </div>
                            <div className="card-body">
                                <WalletBalances />
                            </div>
                        </div>
                        <div className="card mt-2">
                            <div className="card-body">
                                <LogTable />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
