import React, { useEffect, useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { LuUserCircle } from "react-icons/lu";
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { setIsLogged, setUserDetails, toggleMode } from '../../redux/userSlice';
import { setCoinData, setSelectedPairs, setTradePairs } from '../../redux/coinSlice'; // Import setCoinData
import axios from 'axios';
import { Nav, Navbar } from 'react-bootstrap';

export default function Header() {
    const [isFixed, setIsFixed] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isLogged = useSelector((state) => state.user?.isLogged);
    const userDetails = useSelector(state => state.user?.userDetails);
    const mode = useSelector((state) => state.user?.mode);
    const location = useLocation();

    // Fetch coins and update Redux state
    useEffect(() => {
        const fetchData = async () => {
            const coinRequest = axios.get(`${process.env.REACT_APP_API_URL}/coins`, { withCredentials: true });
            const tradePairRequest = axios.get(`${process.env.REACT_APP_API_URL}/tradePairs`, { withCredentials: true });

            try {
                const [coinsResponse, tradePairsResponse] = await Promise.all([coinRequest, tradePairRequest]);

                if (coinsResponse.data) {
                    dispatch(setCoinData(coinsResponse.data));
                }

                if (tradePairsResponse.data) {
                    dispatch(setTradePairs(tradePairsResponse.data));
                    const defaultPair = tradePairsResponse.data.find(pair => pair.default);

                    // Check the current path
                    if (location.pathname === '/ae') {
                        // Find the pair where coin1 is BTC and coin2 is AED
                        const selectedPair = tradePairsResponse.data.find(
                            (pair) => pair?.coin1?.shortCode === 'BTC' && pair.coin2?.shortCode === 'AED'
                        );

                        // Dispatch setSelectedPairs if the pair is found
                        if (selectedPair) {
                            dispatch(setSelectedPairs(selectedPair));
                        } else {
                            dispatch(setSelectedPairs(defaultPair));
                            console.warn('No matching trade pair found for BTC/AED');
                        }
                    } else {
                        dispatch(setSelectedPairs(defaultPair));
                    }
                }
            } catch (error) {
                console.error("Error fetching data:", error);
                // Optionally dispatch an error action or set error state here
            }
        };

        fetchData();
    }, [dispatch, location.pathname]); // Ensure location.pathname is included in the dependency array
    // Empty dependency array ensures this runs once on mount

    const logOut = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL}/users/logout`, { withCredentials: true });
            if (response) {
                dispatch(setIsLogged(false));
                dispatch(setUserDetails({}));
                window.localStorage.clear();
                navigate('/');
                setExpanded(false); // Close the navbar after logging out
            }
        } catch (error) {
            console.log(error);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 100) {
                setIsFixed(true);
            } else {
                setIsFixed(false);
            }
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleNavLinkClick = () => {
        setExpanded(false); // Close the navbar after clicking a link
    };

    return (
        <header className={`header-section header-section--style2 ${isFixed ? 'header-fixed' : ''}`}>
            <div className="header-bottom">
                <Navbar expanded={expanded} onToggle={() => setExpanded(!expanded)} collapseOnSelect expand="lg" className={`bg-body-tertiary ${mode === 'dark' ? 'dark' : ''}`}>
                    <div className="container">
                        <Navbar.Brand to="/" as={Link} onClick={handleNavLinkClick}>
                            {mode === 'dark' ?
                                <img className="dark logo" src="/assets/images/logo-dark.png" alt="logo" />
                                :
                                <img className="dark logo" src="/assets/images/logo-light.png" alt="logo" />
                            }

                        </Navbar.Brand>
                        <Navbar.Toggle className={`${mode === 'dark' ? 'dark' : ''}`} aria-controls="responsive-navbar-nav" />
                        <Navbar.Collapse id="responsive-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link className={`${mode === 'dark' ? 'text-light' : 'text-dark'}`} to="/buy-and-sell" as={Link} onClick={handleNavLinkClick}>Buy & Sell</Nav.Link>
                                <Nav.Link className={`${mode === 'dark' ? 'text-light' : 'text-dark'}`} to="/institutions" as={Link} onClick={handleNavLinkClick}>Institutions</Nav.Link>
                                <Nav.Link className={`${mode === 'dark' ? 'text-light' : 'text-dark'}`} to="/otc" as={Link} onClick={handleNavLinkClick}>OTC Desk</Nav.Link>
                                <Nav.Link className={`${mode === 'dark' ? 'text-light' : 'text-dark'}`} to="/contact-us" as={Link} onClick={handleNavLinkClick}>Contact Us</Nav.Link>
                            </Nav>
                            <Nav className='me-5'>
                                {isLogged ?
                                    <>
                                        {userDetails?.role === "admin" ?
                                            <>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic" className={`${mode === 'dark' ? 'text-light' : 'text-dark'}`}>
                                                        Manage Users
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item to="/admin/users" as={Link} onClick={handleNavLinkClick}>Users</Dropdown.Item>
                                                        <Dropdown.Item to="/admin/users-kyc" as={Link} onClick={handleNavLinkClick}>Users KYC</Dropdown.Item>
                                                        <Dropdown.Item to="/admin/admins" as={Link} onClick={handleNavLinkClick}>Admins</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic" className={`${mode === 'dark' ? 'text-light' : 'text-dark'}`}>
                                                        Manage Coins
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item to="/admin/coins" as={Link} onClick={handleNavLinkClick}>Coins</Dropdown.Item>
                                                        <Dropdown.Item to="/admin/coins/trade-pairs" as={Link}>Trade Pairs</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </>
                                            :
                                            <>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic" className={`${mode === 'dark' ? 'text-light' : 'text-dark'}`}>
                                                        Wallets
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item to="/client/wallets/balances" as={Link} onClick={handleNavLinkClick}>Balances</Dropdown.Item>
                                                        <Dropdown.Item to="/client/wallets/deposits" as={Link} onClick={handleNavLinkClick}>Deposit</Dropdown.Item>
                                                        <Dropdown.Item to="/client/wallets/withdraws" as={Link} onClick={handleNavLinkClick}>Withdraw</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <Dropdown>
                                                    <Dropdown.Toggle variant="basic" id="dropdown-basic" className={`${mode === 'dark' ? 'text-light' : 'text-dark'}`}>
                                                        History
                                                    </Dropdown.Toggle>
                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="/" onClick={handleNavLinkClick}>Trade History</Dropdown.Item>
                                                        <Dropdown.Item to="/client/history/transactions" as={Link} onClick={handleNavLinkClick}>Transactions History</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </>
                                        }
                                        <Dropdown>
                                            <Dropdown.Toggle as="div" id="dropdown-basic" className="d-flex align-items-center mt-2">
                                                {userDetails?.avatar ? <img className='img-fluid rounded-circle' style={{ height: '30px' }} src={userDetails?.avatar} alt={userDetails?.FirstName} /> : <LuUserCircle size={20} />}
                                            </Dropdown.Toggle>
                                            <Dropdown.Menu>
                                                {userDetails?.role === "admin" ? <Dropdown.Item as={Link} to="/admin/dashboard" onClick={handleNavLinkClick}>Dashboard</Dropdown.Item> : <Dropdown.Item as={Link} to="/client/dashboard" onClick={handleNavLinkClick}>Dashboard</Dropdown.Item>}
                                                {userDetails?.role === "admin" ? <Dropdown.Item as={Link} to="/admin/settings" onClick={handleNavLinkClick}>Settings</Dropdown.Item> : <Dropdown.Item as={Link} to="/client/settings" onClick={handleNavLinkClick}>Settings</Dropdown.Item>}
                                                <Dropdown.Item to="/client/profile-update" as={Link} onClick={handleNavLinkClick}>Profile</Dropdown.Item>
                                                <Dropdown.Item onClick={() => { logOut(); handleNavLinkClick(); }}>Sign Out</Dropdown.Item>
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </>
                                    :
                                    <>
                                        <Link className={`nav-link ${mode === 'dark' ? 'text-light' : 'text-dark'}`} to="/signin" onClick={handleNavLinkClick}>Sign In</Link>
                                        <Nav.Link to="/signup" className="trk-btn trk-btn--border trk-btn--primary" as={Link} onClick={handleNavLinkClick}><span>Get Started</span></Nav.Link>
                                    </>
                                }
                            </Nav>
                        </Navbar.Collapse>
                        <div class="lightdark-switch d-none d-md-block">
                            <span class="switch-btn dark-switcher" id="btnSwitch" onClick={() => dispatch(toggleMode())}>
                                {mode === 'light' ? <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/icon/moon.svg`} alt="light-dark-switchbtn" class="swtich-icon" /> : <img src={`${process.env.REACT_APP_BASE_URL}/assets/images/icon/sun.svg`} alt="light-dark-switchbtn" class="swtich-icon" />

                                }
                            </span>
                        </div>
                    </div>
                </Navbar>
            </div >
        </header >
    );
}
